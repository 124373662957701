<template>
  <div class="modal is-active">
    <div class="modal-background" @click="close"></div>
    <div class="modal-content">
      <div class="carousel-container">
        <button :style="{ visibility: currentImage > 0 ? 'visible' : 'hidden' }" class="prev" @click="prevImage">❮</button>
        <div class="image-container">
          <img :src="images[currentImage].url" alt="Imagen del producto" />
        </div>
        <button :style="{ visibility: currentImage < images.length - 1 ? 'visible' : 'hidden' }" class="next" @click="nextImage">❯</button>
      </div>
    </div>
    <button class="delete" @click="close"></button>
  </div>
</template>

<script>
export default {
  props: {
    images: Array,
    currentImage: Number
  },
  methods: {
    prevImage () {
      if (this.currentImage > 0) {
        this.$emit('update-model-value', this.currentImage - 1)
      }
    },
    nextImage () {
      if (this.currentImage < this.images.length - 1) {
        this.$emit('update-model-value', this.currentImage + 1)
      }
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style scoped>
.carousel-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.image-container img {
  max-width: 100%;
  max-height: 500px;
}
button {
  background-color: transparent;
  border: none;
  font-size: 3rem;
  color: white;
  cursor: pointer;
  padding: 20px;
  width: 80px;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
button:focus {
  outline: none;
}
.prev {
  margin-right: 10px;
}
.next {
  margin-left: 10px;
}
</style>
